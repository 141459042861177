const links = [
	{
		text: 'Home',
		url: '/',
		hideOnDesktop: true,
		hideOptions: ({ loggedIn }) => loggedIn,
	},
	{ text: 'Services', url: '/services', hideOptions: () => true },
	{ text: 'Principles', url: '/principles', hideOptions: () => true },
	{ text: 'Open Source', url: '/open-source', hideOptions: () => true },
	{ text: 'Our Work', url: '/work', hideOptions: () => true },
	{ text: 'Blog', url: '/blog', hideOptions: () => true },
	{ text: 'Contact', url: '/contact', hideOptions: () => true },
];

export default links;
