// Dependencies
import React, { useState, useEffect } from 'react';
import { NavBar } from '@anephenix/ui';
import Logo from '../logo/Logo';
import Link from 'next/link';
import links from '../../../data/navbar-links';


const NavBarWrapper = () => {
	const [hasScrolled, setHasScrolled] = useState('');

	const checkScroll = () => {
		if (window.scrollY >= 80) {
			setHasScrolled('scrolled');
		} else {
			setHasScrolled('');
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', checkScroll);
		// return window.removeEventListener('scroll', checkScroll);
	});

	return (
		<NavBar
			logo={<Logo />}
			className={hasScrolled}
			links={links}
			Link={Link}
			loggedIn={false}
		/>
	);
};

export default NavBarWrapper;
