const Image = () => (
	<svg width="229px" height="229px" viewBox="0 0 229 229" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		<defs>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
				<stop stopColor="#FAD961" stopOpacity="0.107736014" offset="0%"></stop>
				<stop stopColor="#F76B1C" stopOpacity="0.330610795" offset="100%"></stop>
			</linearGradient>
			<radialGradient cx="50%" cy="61.6299313%" fx="50%" fy="61.6299313%" r="75.7644726%" gradientTransform="translate(0.500000,0.616299),scale(1.000000,0.842424),rotate(88.052057),translate(-0.500000,-0.616299)" id="radialGradient-2">
				<stop stopColor="#FAD961" offset="0%"></stop>
				<stop stopColor="#F76B1C" offset="100%"></stop>
			</radialGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
				<stop stopColor="#FFAE50" stopOpacity="0.144106658" offset="0.472445101%"></stop>
				<stop stopColor="#FFB327" stopOpacity="0.107025589" offset="100%"></stop>
			</linearGradient>
			<radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="100%" gradientTransform="translate(0.500000,0.500000),scale(0.411765,1.000000),rotate(90.000000),scale(1.000000,1.845534),translate(-0.500000,-0.500000)" id="radialGradient-4">
				<stop stopColor="#FAD961" offset="0%"></stop>
				<stop stopColor="#F76B1C" offset="100%"></stop>
			</radialGradient>
			<radialGradient cx="913.722743%" cy="50%" fx="913.722743%" fy="50%" r="2281.3688%" gradientTransform="translate(9.137227,0.500000),scale(1.000000,0.016659),rotate(180.000000),scale(1.000000,4.275430),translate(-9.137227,-0.500000)" id="radialGradient-5">
				<stop stopColor="#FAD961" offset="0%"></stop>
				<stop stopColor="#F88D32" offset="48.4612542%"></stop>
				<stop stopColor="#F76B1C" offset="100%"></stop>
			</radialGradient>
			<radialGradient cx="1285.86917%" cy="50%" fx="1285.86917%" fy="50%" r="13639.8597%" gradientTransform="translate(12.858692,0.500000),scale(0.167257,1.000000),rotate(180.000000),translate(-12.858692,-0.500000)" id="radialGradient-6">
				<stop stopColor="#FAD961" offset="0%"></stop>
				<stop stopColor="#F76B1C" offset="100%"></stop>
			</radialGradient>
			<radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="44.4554657%" gradientTransform="translate(0.500000,0.500000),scale(1.000000,0.852259),translate(-0.500000,-0.500000)" id="radialGradient-7">
				<stop stopColor="#FAD961" offset="0%"></stop>
				<stop stopColor="#F76B1C" offset="100%"></stop>
			</radialGradient>
		</defs>
		<g id="LabItem" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Desktop" transform="translate(-712.000000, -599.000000)">
				<g id="Group-Four" transform="translate(713.000000, 600.000000)">
					<g id="Unknown" transform="translate(44.000000, 27.000000)">
						<path d="M47.6571429,16.6058804 C45.6336301,14.9949428 44.48,13.1372704 44.48,11.1594203 C44.48,4.99624264 55.6818356,0 69.5,0 C83.3181647,0 94.52,4.99624264 94.52,11.1594203 C94.52,13.1372704 93.3663699,14.9949428 91.3428571,16.6058804 L91.3428571,52.3875862 C96.2720934,55.3741568 99.2857143,59.3733544 99.2857143,63.7681159 C99.2857143,67.5249447 116.731049,83.1029411 128.35488,100.274085 C135.098708,106.784334 139,114.492038 139,122.753623 C139,124.702826 138.782832,126.621198 138.362401,128.500285 C137.953852,131.835787 136.712711,134.990241 134.420245,137.867492 C124.416712,153.734701 99.1226252,165 69.5,165 C39.8773752,165 14.583288,153.734701 4.57975561,137.867492 C2.2872896,134.990241 1.04614825,131.835787 0.63759901,128.500285 C0.217168071,126.621198 0,124.702826 0,122.753623 C0,114.492038 3.90129138,106.784334 10.6451179,100.274088 C22.2689511,83.1029411 39.7142857,67.5249447 39.7142857,63.7681159 C39.7142857,59.3733544 42.7279068,55.3741568 47.6571429,52.3875862 L47.6571429,16.6058804 Z" id="Combined-Shape" stroke="url(#radialGradient-2)" fill="url(#linearGradient-1)" fillRule="nonzero"></path>
						<ellipse id="Oval-5" stroke="url(#radialGradient-4)" fill="url(#linearGradient-3)" fillRule="nonzero" cx="70" cy="12" rx="17" ry="7"></ellipse>
						<g id="Group-11" strokeLinecap="square" transform="translate(108.664819, 93.386119) rotate(-39.000000) translate(-108.664819, -93.386119) translate(105.884819, 68.675974)">
							<line x1="0.397142857" y1="0.605406888" x2="0.397142857" y2="48.2844321" stroke="#F88D32"></line>
							<line x1="5.16285714" y1="48.2395355" x2="0.397142857" y2="48.2395355" stroke="#F88D32"></line>
							<line x1="5.16285714" y1="44.2540283" x2="0.397142857" y2="44.2540283"  stroke="#F88D32"></line>
							<line x1="5.16285714" y1="40.268521" x2="0.397142857" y2="40.268521" stroke="#F88D32"></line>
							<line x1="5.16285714" y1="36.2830138" x2="0.397142857" y2="36.2830138"  stroke="#F88D32"></line>
							<line x1="5.16285714" y1="32.2975065" x2="0.397142857" y2="32.2975065" stroke="#F88D32"></line>
							<line x1="5.16285714" y1="28.3119993" x2="0.397142857" y2="28.3119993" stroke="#F88D32"></line>
							<line x1="5.16285714" y1="24.326492" x2="0.397142857" y2="24.326492" stroke="#F88D32"></line>
							<line x1="5.16285714" y1="20.3409848" x2="0.397142857" y2="20.3409848"  stroke="#F88D32"></line>
							<line x1="5.16285714" y1="16.3554775" x2="0.397142857" y2="16.3554775"  stroke="#F88D32"></line>
							<line x1="5.16285714" y1="12.3699703" x2="0.397142857" y2="12.3699703" stroke="#F88D32"></line>
							<line x1="5.16285714" y1="8.38446304" x2="0.397142857" y2="8.38446304"  stroke="#F88D32"></line>
							<line x1="5.16285714" y1="4.39895577" x2="0.397142857" y2="4.39895577"  stroke="#F88D32"></line>
							<line x1="5.16285714" y1="0.413448519" x2="0.397142857" y2="0.413448519" stroke="#F88D32"></line>
						</g>
					</g>
					<circle id="Oval" stroke="url(#radialGradient-7)" cx="113.5" cy="113.5" r="113.5"></circle>
				</g>
			</g>
		</g>
	</svg>
);

export default Image;