import styles from './Description.module.scss';

const Description = ({ text, icon }) => (
	<div className={styles.description} key={text}>
		<div className={styles['description-text']}>{text}</div>
		<div className={styles['description-image']}>{icon}</div>
	</div>
);

export default Description;
