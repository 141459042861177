
import styles from './Footer.module.scss';
import Link from 'next/link';

const footerLinks = [
	{text: 'Privacy Policy', url: '/privacy'},
	{text: 'Cookies', url: '/cookies'},
	{text: 'GDPR', url: '/gdpr'}
];

const FooterLink = ({text, url}, index) => (<li key={index}>
	<Link key={text} href={url}>{text}</Link>
</li>);

const year = new Date().getFullYear();

const Footer = () => (
	<footer id={styles.footer}>
		<div className={styles.container + ' container'}>
			<div className={styles.withSidePadding + ' withSidePadding'}>
				<div id={styles['copyright-and-licence']}>&copy;&nbsp;{year} Anephenix. All Rights Reserved.</div>
				<div id={styles['footer-links']}>
					<ul>
						{footerLinks.map(FooterLink)}
					</ul>
				</div>
			</div>
		</div>
	</footer>
);
			
export default Footer;