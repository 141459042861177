// Dependencies
import Page from '../src/components/page/Page';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Link from 'next/link';

// Components
import Hero from '../src/components/hero/Hero';
import Description from '../src/components/description/Description';
import Clients from '../src/components/clients/Clients';
import ContactUs from '../src/components/contact-us/ContactUs';
import AnimatedHero from '../src/components/graphics/AnimatedHero';
import LabBottle from '../src/components/graphics/LabBottle';

// Helpers

const sendSignal = (signal) => {
	return () => {
		if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
			console.log('Sending message to the signal controller to:', signal);
			navigator.serviceWorker.controller.postMessage(signal);
		}	
	};
};

const ServiceWorkerButtons = () => (
	<p>
		<button onClick={sendSignal('start')}>Start</button>
		<button onClick={sendSignal('stop')}>Stop</button>
	</p>
);

// Styling
const descriptionText = (
	<>
		<p>
			We are a digital studio for web products & services, running a
			product lab for creating new businesses.
		</p>
		<p>
			We also work with clients to develop products for their customers at
			rapid speed - from initial concept right through to the delivered
			solution.
		</p>
		<p>
			<Link href="/services" className="button">Find out more</Link>
		</p>
	</>
);

const HomePage = observer(() => {
	const [serviceWorkerEnabled, setServiceWorkerEnabled] = useState(false);
	const heroIcon = <AnimatedHero />;
	const descriptionIcon = <LabBottle />;

	useEffect(() => {
		if ('serviceWorker' in navigator) {
			const searchParams = new URLSearchParams(document.location.search);
			const enabledOrNot = searchParams.get('sw') === 'true';	
			setServiceWorkerEnabled(enabledOrNot);
		}
	}, []);

	return (
		<Page>
			<Hero text="Welcome to Anephenix." icon={heroIcon} />
			<Description text={descriptionText} icon={descriptionIcon} />
			{serviceWorkerEnabled && <ServiceWorkerButtons />}
			<Clients />
			<ContactUs />
		</Page>
	);
});

export default HomePage;
