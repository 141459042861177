import styles from './Hero.module.scss';

const Hero = ({ text, icon }) => (
	<div id={styles.hero}>
		<div id={styles['hero-text']}>{text}</div>
		{icon}
	</div>
);

export default Hero;
