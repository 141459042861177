const iterations = [
	330, 300, 270, 240, 210
];

import styles from '../hero/Hero.module.scss';

const Ellipse = (rotation, index) => {
	const transform = `translate(113.500000, 113.500000) rotate(-${rotation}.000000) translate(-113.500000, -113.500000)`;
	const id = styles[`ellipse-${index}`];
	return(
		<ellipse id={id} key={index} transform={transform} cx="113.5" cy="113.5" rx="32.5" ry="111.5"></ellipse>
	);};

const Graphic = () => (
	<svg id={styles.ident} width="227px" height="227px" viewBox="0 0 227 227" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		<defs>
			<radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="171.74074%" gradientTransform="translate(0.500000,0.500000),scale(1.000000,0.291480),translate(-0.500000,-0.500000)" id="radialGradient-1">
				<stop stopColor="#FAD961" offset="0%"></stop>
				<stop stopColor="#F76B1C" offset="100%"></stop>
			</radialGradient>
		</defs>
		<g id="Ellipse-Group" fill="none" stroke="url(#radialGradient-1)">
			<ellipse id={styles['ellipse-original']} cx="113.5" cy="113.5" rx="32.5" ry="111.5"></ellipse>
			{iterations.map(Ellipse)}
		</g>
	</svg>);

export default Graphic;