
import styles from './Clients.module.scss';
import Link from 'next/link';
import VolvoLogo from '../graphics/VolvoLogo';

const Clients = () => (
	<div id={styles.clients}>
		<h2>Clients we've worked with</h2>
		<VolvoLogo />
		<Link href="/work" className="button">See our work</Link>
	</div>
);
			
export default Clients;