import styles from './Logo.module.scss';
import Link from 'next/link';

const SVGLogo = () => (
	<svg
		width="102px"
		height="13px"
		viewBox="0 0 102 13"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<title>ANEPHENIX</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M1.818,13 L3.132,9.868 L9.504,9.868 L10.8,13 L12.564,13 L7.146,0.256 L5.724,0.256 L0.108,13 L1.818,13 Z M8.928,8.464 L3.726,8.464 L6.372,2.2 L6.408,2.2 L8.928,8.464 Z M15.768,13 L15.768,2.416 L15.804,2.416 L23.184,13 L25.092,13 L25.092,0.256 L23.58,0.256 L23.58,10.84 L23.544,10.84 L16.164,0.256 L14.256,0.256 L14.256,13 L15.768,13 Z M36.594,13 L36.594,11.596 L29.772,11.596 L29.772,7.06 L35.838,7.06 L35.838,5.656 L29.772,5.656 L29.772,1.66 L36.27,1.66 L36.27,0.256 L28.26,0.256 L28.26,13 L36.594,13 Z M40.446,13 L40.446,7.276 L43.002,7.276 C43.446,7.276 43.914,7.222 44.406,7.114 C44.898,7.006 45.351,6.82 45.765,6.556 C46.179,6.292 46.518,5.932 46.782,5.476 C47.046,5.02 47.178,4.444 47.178,3.748 C47.178,3.088 47.055,2.533 46.809,2.083 C46.563,1.633 46.233,1.273 45.819,1.003 C45.405,0.733 44.931,0.541 44.397,0.427 C43.863,0.313 43.302,0.256 42.714,0.256 L42.714,0.256 L38.934,0.256 L38.934,13 L40.446,13 Z M43.002,5.872 L40.446,5.872 L40.446,1.66 L43.002,1.66 C43.758,1.66 44.373,1.831 44.847,2.173 C45.321,2.515 45.558,3.046 45.558,3.766 C45.558,4.486 45.321,5.017 44.847,5.359 C44.373,5.701 43.758,5.872 43.002,5.872 L43.002,5.872 Z M51.12,13 L51.12,7.06 L57.924,7.06 L57.924,13 L59.436,13 L59.436,0.256 L57.924,0.256 L57.924,5.656 L51.12,5.656 L51.12,0.256 L49.608,0.256 L49.608,13 L51.12,13 Z M70.938,13 L70.938,11.596 L64.116,11.596 L64.116,7.06 L70.182,7.06 L70.182,5.656 L64.116,5.656 L64.116,1.66 L70.614,1.66 L70.614,0.256 L62.604,0.256 L62.604,13 L70.938,13 Z M74.79,13 L74.79,2.416 L74.826,2.416 L82.206,13 L84.114,13 L84.114,0.256 L82.602,0.256 L82.602,10.84 L82.566,10.84 L75.186,0.256 L73.278,0.256 L73.278,13 L74.79,13 Z M88.794,13 L88.794,0.256 L87.282,0.256 L87.282,13 L88.794,13 Z M92.196,13 L95.994,7.348 L99.774,13 L101.772,13 L97.074,6.286 L101.358,0.256 L99.468,0.256 L96.066,5.242 L92.628,0.256 L90.684,0.256 L94.968,6.286 L90.324,13 L92.196,13 Z"
				id="ANEPHENIX"
				fill="#000000"
				fillRule="nonzero"
			></path>
		</g>
	</svg>
);

const Logo = () => (
	<div id={styles.logo}>
		<Link href="/">
			<SVGLogo />
		</Link>
	</div>
);

export default Logo;
